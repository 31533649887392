import { $get, $post } from "@/utils/request";

const api = {
  // 获取配置信息
  getConf: (account) => $get(`external/card/pickup/online/getConf/${account}`),
  // 获取卡券过期时间
  getExpirationTime: (account) =>
    $get(`external/card/pickup/online/getExpirationTime/${account}`),
  // 获取卡券可使用门店
  getAvailableStores: (account) =>
    $get(`external/card/pickup/online/getAvailableStores/${account}`),
  getStore: (data) =>
    $get(`external/card/pickup/online/getAvailableStores/getStore`, data),
  // 添加卡券访问记录
  addVisitLog: (data) => $post("external/card/pickup/online/addVisitLog", data),
  // 获取卡券信息（只传卡号）
  getCardByAccount: (data) =>
    $post("external/card/pickup/online/getCardByAccount", data),
  // 获取卡券信息
  getCard: (data) => $post("external/card/pickup/online/getCard", data),
  // 新增/修改预约信息
  editReservation: (data) =>
    $post("external/card/pickup/online/editReservation", data),
  // 获取预约信息
  getReservation: (data) =>
    $post("external/card/pickup/online/getReservation", data),
  // 获取快递单号
  getExpressDelivery: (data) =>
    $post("external/card/pickup/online/getExpressDelivery", data),
  // 获取省市
  getProvince: (data) => $get(`external/card/pickup/online/getProvince`, data),
  // 获取提货推荐搭配列表
  getCardPickupCombination: () =>
    $get(`external/card/pickup/online/getCardPickupCombination`),
  // 地址识别（线上提货用
  apiNlpaddress: (data) =>
    $post(`external/card/pickup/online/nlpaddress`, data),
};
export { api };
